
@media print {
    body {
        margin: 0 !important;
    }
}


@font-face {
    font-weight: 200;
    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-ExtraLight.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-ExtraLight.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-weight: 300;
    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-Light.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-Light.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-Light.ttf") format("truetype");
}

@font-face {
    font-weight: 400;
    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-Book.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-Book.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-Book.ttf") format("truetype");
}

@font-face {
    font-weight: 500;
    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-Medium.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-Medium.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-Medium.ttf") format("truetype");
}

@font-face {
    font-weight: 700;
    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-Bold.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-Bold.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-Bold.ttf") format("truetype");

}

@font-face {
    font-weight: 800;

    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-ExtraBold.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-ExtraBold.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-ExtraBold.ttf") format("truetype");

}

@font-face {
    font-weight: 900;

    font-family: Pragmatica;
    src: url("./fonts/Pragmatica/Pragmatica-Black.woff2") format('woff2'),
    url("./fonts/Pragmatica/Pragmatica-Black.woff") format('woff'),
    url("./fonts/Pragmatica/Pragmatica-Black.ttf") format("truetype");

}

/* PragmaticaExtended */

@font-face {
    font-weight: 200;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-ExtraLight.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-ExtraLight.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-ExtraLight.ttf") format("truetype");

}

@font-face {
    font-weight: 300;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-Light.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Light.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Light.ttf") format("truetype");

}

@font-face {
    font-weight: 400;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-Book.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Book.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Book.ttf") format("truetype");

}

@font-face {
    font-weight: 500;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-Medium.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Medium.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Medium.ttf") format("truetype");

}

@font-face {
    font-weight: 700;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-Bold.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Bold.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Bold.ttf") format("truetype");

}

@font-face {
    font-weight: 800;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-ExtraBold.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-ExtraBold.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-ExtraBold.ttf") format("truetype");

}

@font-face {
    font-weight: 900;

    font-family: "Pragmatica Extended";
    src: url("./fonts/PragmaticaExtended/PragmaticaExtended-Black.woff2") format('woff2'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Black.woff") format('woff'),
    url("./fonts/PragmaticaExtended/PragmaticaExtended-Black.ttf") format("truetype");

}


.main-container {
    margin-left: auto;
    margin-right: auto;
}

.ck-content {
    font-family: Pragmatica, Arial, sans-serif;
    line-height: 1.6;
    word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
    max-width: 100%;
}

body, p {
    font-family: Pragmatica, Arial, sans-serif;
}

h1 {
    font-family: "Pragmatica Extended", Arial, sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 100%;
    font-style: normal;
}

h2 {
    font-family: "Pragmatica Extended", Arial, sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    font-style: normal;
}

h3 {
    font-family: "Pragmatica Extended", Arial, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    font-style: normal;
}

h4 {
    font-family: "Pragmatica Extended", Arial, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    font-style: normal;
}

h5 {
    font-family: "Pragmatica Extended", Arial, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
}

h6 {
    font-family: "Pragmatica Extended", Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
}

